html, body, #root {
  height: 100%;
}

.leaflet-container {
  height: 100%;
  flex-grow: 1;
}

.leaflet-popup-content {
  text-align: center;
  min-width: 150px;
  width: auto !important;
}

.makeStyles-openButtomLeft-82 {
  right: -37px;
}

.MuiTableCell-root.actions {
  text-align: center!important;
  border-left: 1px solid #d7d7d7;
}

.MuiTableCell-root.actions button {
  padding: 0 5px;
}

.MuiTableCell-root.actions button:first-child {
  padding: 0 5px 0 0;
}

.MuiTableCell-root.actions button:last-child {
  padding: 0 0 0 5px;
}

.MuiTableCell-root.actions button.MuiButton-sizeSmall {
  padding: 4px 10px;
}

.MuiTableCell-root.actions.two-icons {
  width: 60px;
}

.MuiTableCell-root.actions.three-icons {
  width: 90px;
}

.MuiTableCell-root.actions.two-icons.one-disabled {
  text-align: left !important;
}

.MuiTypography-root.ruc-title-modal-confirmation {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 40px;
  line-height: 1.7rem;
  font-size: 1.25rem;
  font-weight: 300;
  color: #e0e0e0
}

.MuiTypography-root.ruc-icon-modal-confirmation svg {
  font-size: inherit;
  color: #e0e0e0
}

.MuiTypography-root.ruc-title-modal-confirmation span.ruc-confirmation-question {
  text-align: center;
  color: gray
}

.MuiTypography-root.ruc-title-modal-confirmation span.ruc-confirmation-item {
  display: block;
  text-transform: lowercase;
  /* font-style: italic; */
  color: #d32f2f
}

.MuiTypography-root.ruc-title-modal-confirmation+.MuiGrid-root,
.MuiTypography-root.ruc-title-modal-confirmation+.MuiGrid-root+.MuiGrid-root {
  text-align: center;
}

fieldset.ruc-form-fieldset {
  border-radius: 4px;
  border: 0.05rem solid #B7B7B7;
  margin-bottom: 20px;
  padding: 20px 20px 10px 20px;
}

fieldset.ruc-form-fieldset legend {
  color: rgba(0, 0, 0, 0.54);
  padding: 0 10px;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.makeStyles-wrapper-72 .MuiListItemIcon-root, .makeStyles-wrapper-72 .MuiListItemText-root { color: #757687 }
.ruc-amm-page .makeStyles-wrapper-72 .MuiFab-primary { background-color: #A7B4CC }
.ruc-amm-page .makeStyles-wrapper-72 .MuiFab-primary:hover { background-color: #757687 }
.ruc-collapse-table-cell .MuiCollapse-container .MuiCollapse-wrapperInner .MuiBox-root { margin: 24px }
.MuiTableCell-root.MuiTableCell-body.ruc-collapse-table-cell { padding: 0 }
.ruc-collapse-table-cell .MuiCollapse-container { border-top: 1px solid #D9D9D9 }
.ruc-collapse-table-cell .MuiCollapse-container .MuiCollapse-wrapperInner { background: #F1F1EC }
.ruc-collapse-table-cell .MuiCollapse-container .MuiCollapse-wrapperInner .MuiTypography-root { color: #144EB3 }
.ruc-collapse-table-cell .MuiCollapse-container .MuiCollapse-wrapperInner table { background: white }
.ruc-collapse-table-cell .MuiCollapse-container .MuiCollapse-wrapperInner .MuiTableHead-root { background: #F8F8FF }
.ruc-collapse-table-cell .MuiCollapse-wrapperInner .MuiTableHead-root .MuiTableRow-head th.MuiTableCell-root { color: #757687 }
.ruc-collapse-table-cell .MuiCollapse-wrapperInner table.MuiTable-root { height: 160px }
.ruc-collapse-table-cell .MuiCollapse-wrapperInner textarea { height: 123px }
.ruc-collapse-table-cell .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body { color:#000 }
.ruc-icon-read { color: #008FDF }
.ruc-icon-managed { color: #006B3C }
.ruc-icon-unmanaged_close { color: #9C9C9C }
.ruc-history-icon { float: left; margin-right: 10px }
.ruc-collapse-table-cell .MuiCollapse-wrapperInner textarea { font-size: 14px }
.ruc-info-icon-open { color: #144EB3 }
.ruc-info-icon-close { color: #96A5C1 }
.ruc-alert-manage-popup svg { color: #144EB3 }
.ruc-alert-managed-popup svg { color: #144EB3 }
/* .ruc-alert-managed-popup svg { color: #006B3C } */
.ruc-white-background-wrapper { width: 100%; background: white }
.ruc-alert-box.ruc-warning-alert-box { background-color: #FFF7D6; color: #A38100 }
.ruc-alert-box svg { font-size: 2rem }
.ruc-alert-box svg + span { margin-left: 10px; line-height: 2.3rem }

.ruc-indicators-pending-progress.MuiGrid-root{
  padding-top:6px;
}

.ruc-indicators-by-category span {
  font-size: 12px;
}

[class*="ruc-indicators-button-category"] .category-filter-detail label+span {
  display: block;
  width: 100%;
}

[class*="ruc-indicators-button-category"] .category-filter-detail label+span * {
  float: left;
}

[class*="ruc-indicators-button-category"] .category-filter-detail label+span svg+span {
  margin: 0.15rem 0 0 0.5rem;
}

/* .ruc-managed-alert td {
  background: #478E22;
  color: white!important;
} */



.ruc-priority-circle-block,
.ruc-status-circle-block {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  border-radius: 30px;
  line-height: 32px;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ruc-status-circle-block {
  background: #D21F3E;
}

.ruc-managed-alert .ruc-status-circle-block {
  background: #478E22;
}

.ruc-unmanaged-alert .ruc-status-circle-block {
  background: #9C9C9C;
}

.ruc-alert-box {
  display: flex;
  padding: 15px;
  font-size: 1rem;
  font-family: "Roboto Helvetica Arial",sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: transparent;
}

[class*="ruc-indicators-button-category"] {
  color: black!important;
  background-color: white!important
}

.ruc-indicators-button-group button.ruc-indicators-button-totals,
.ruc-indicators-button-group button.ruc-indicators-button-managed,
.ruc-indicators-button-group button.ruc-indicators-button-pending {
  width: 122px;
}

.ruc-indicators-table .ruc-indicators-button-group [class*='ruc-indicators-button'] [class*="ruc-indicators-button-category"] label+span svg {
  float: left;
}

.ruc-indicators-table .ruc-indicators-button-group [class*='ruc-indicators-button'] [class*="ruc-indicators-button-category"] label+span span {
  float: left;
  line-height: 24px;
  margin-left: 10px;
}

.ruc-indicators-grid {
  margin-bottom: 30px;
  border: 1px solid #D9D9D9;
  background-color: white;
}

.ruc-float-right {
  float: right;
}

.ruc-alert-popup-modal [tabindex="-1"] {
  width: 750px;
  padding: 0;
  border: 0!important;
  border-radius: 4px;
}

.ruc-popup-content-list {
  margin: 0;
  padding: 10px;
  list-style: none;
  font-size: 1rem;
  font-family: "Roboto Helvetica Arial",sans-serif;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.ruc-alert-comment {
  margin: 10px 10px 0 0;
}

.ruc-popup-content-list.ruc-popup-item {
  margin-right: 10px;
}
.ruc-popup-content-list.ruc-popup-client {
  margin-bottom: 10px;
}

.ruc-popup-content-list li label {
  display: inline-block;
  font-weight: 300;
}

.ruc-popup-content-list li {
  padding: 5px 0;
}

.ruc-popup-content-list.ruc-popup-item li label { width: 130px }
.ruc-popup-content-list.ruc-popup-client li label { width: 65px }
.ruc-popup-content-list.ruc-popup-vehicle li label { width: 85px }

.ruc-alert-history-comment {
  height: 138px;
  padding: 10px;
  font-family: "Roboto Helvetica Arial",sans-serif;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #FFF;
}

.ruc-popup-alert-history-block {
  margin: 15px 0!important
}

.ruc-popup-alert-history-block .MuiGrid-root.MuiGrid-item {
  padding: 0 8px!important;
}

.ruc-popup-alert-history-block table {
  border: 1px solid #D9D9D9;
}

.ruc-popup-alert-history-block table thead.MuiTableHead-root {
  background: none;
}

.ruc-popup-alert-history-block table thead.MuiTableHead-root th.MuiTableCell-root {
  font-weight: 400;
  color: #0e0e0e
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
      padding-left: 44px!important;
  }
}